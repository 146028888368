body {
  /* background-image: linear-gradient(to left, #ffffff, #51ff005a); */
}
#sidenav-main {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.1), 0 6px 6px rgba(0, 0, 0, 0.12) !important;
  /* background-image: linear-gradient(to right, #cfffb7, #66b759c1); */
  background-color: rgb(255, 255, 255) !important;
  background-image: url(./assets/sidebar.png) !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}
.hover-col:hover .icon-wrapper {
  transform: scale(1.1);
  transition: 0.5s ease-in-out;
}
.active_navlink {
  font-weight: 700;
}
.buttonRemove {
  box-shadow: 0px 1px 2px 0px #1018280d;
  border: 1px solid #d0d5dd;
  padding: 10px 20px !important;
  border-radius: 8px;
  color: #218838;
  background-color: white;
  font-size: 14px;
  font-weight: 600;
  text-transform: capitalize;
}
.table-responsive {
  height: 83vh;
  overflow-y: scroll;
}
.container-fluid {
  padding: 0 0;
}
.footer {
  padding: 0 0;
}
.SelectMUiRow .MuiFormControl-root {
  box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02);
  border-radius: 0.375rem;
}
.SelectMUiRow .MuiOutlinedInput-notchedOutline {
  border: none;
}
.SelectMUiRow .MuiSelect-select {
  padding: 0.625rem 0.75rem !important;
}
.SelectMUiRow_P {
  margin: 0 !important;
  color: #7979799e !important;
}
.city_inputunderline .automComplete .MuiInputBase-root::before {
  border-bottom: none !important;
}
.city_inputunderline .automComplete .MuiInputBase-root:hover {
  border-bottom: none !important;
}
.city_inputunderline .automComplete .MuiInputBase-root::after {
  border-bottom: none !important;
}
